import React from "react"
import { graphql, useStaticQuery } from 'gatsby';
import Image from "../components/image"

const styles = {
    year: {
        textAlign: 'center'
    }
}

const YearlyImages = (props) => {

    const data = useStaticQuery(graphql`
    query {
        houseImages: allFile(filter: {relativePath: {regex: "/house/"}}) {
          totalCount
          edges {
            node {
                id
                name
                childImageSharp {
                    fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }      
  `)

    var month = new Array();
    month[0] = "Januar";
    month[1] = "Februar";
    month[2] = "März";
    month[3] = "April";
    month[4] = "Mai";
    month[5] = "Juni";
    month[6] = "Juli";
    month[7] = "August";
    month[8] = "September";
    month[9] = "Oktober";
    month[10] = "November";
    month[11] = "Dezember";

    const getMonths = () => {
        const imagesPerMonth = [];

        for (let index = 1; index <= 12; index++) {
            let filterString = props.year + index;
            if (index < 10)
                filterString = props.year + "0" + index;

            const monthDate = new Date(props.year, index - 1, 1);
            const images = data.houseImages.edges.filter(image => image.node.name.startsWith(filterString));

            if (images.length > 0)
                imagesPerMonth.push({ month: month[monthDate.getMonth()], isOdd: (index % 2), images });
        }

        return imagesPerMonth;
    }

    return (
        <div>
            <h1 style={styles.year}>{props.year}</h1>

            {getMonths().map(container => (
                <div key={container.month}>
                    <h2>{container.month}</h2>
                    <div>
                        {container.images.map(image => (
                            <Image imageData={image.node} isOdd={container.isOdd} />
                        ))}
                    </div>
                </div>

            ))}
        </div>
    );
}

export default YearlyImages
