import React from "react"
import Img from "gatsby-image"

const styles = {
  thumbnailImage: {
    width: '250px'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '20px 0'
  },
  imageContainerOdd: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    margin: '20px 0'
  },
  line: {
    width: '200px',
    height: '2px',
    backgroundColor: 'black'
  }
};


const Image = (props) => {
  return (
    <div style={props.isOdd ? styles.imageContainer : styles.imageContainerOdd}>
      <Img style={styles.thumbnailImage} fluid={props.imageData.childImageSharp.fluid} />
      <div style={styles.line}></div>
    </div>
  )
}

export default Image
