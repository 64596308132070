import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import YearlyImages from "../components/yearlyImages"


const IndexPage = () => {

    return (
        <Layout>
            <SEO title="House" />
            <YearlyImages year="2019" />
            <YearlyImages year="2020" />

        </Layout>);
}

export default IndexPage
